import Vue from 'vue'
import '../public/config.js'
import App from './App.vue'
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vuex from 'vuex'
import store from '@/store/store.js'
import '../static/css/global.css' /*引入公共样式*/
import * as newapi from '@/api/utils/newindex.js'
import * as utils from "@/utils/utils";
// 将public/config.js中的配置文件地址导入到window.appConfig中


// import CktDesign from "@chuangkit/chuangkit-design";
Vue.config.productionTip = false;
Vue.prototype.$newapi = newapi;
Vue.prototype.$utils = utils;
Vue.prototype.$store = store;
// 添加路由守卫
router.beforeEach((to, from, next) => {
  // 路由发生变化的时候，判断是否登录
  if (to.path === '/login' || to.path === '/') {
    // 如果是登录页面，直接放行
    next()
  } else {
    // 如果不是登录页面，判断是否登录
    if (localStorage.getItem('user') && localStorage.getItem('token')) {
      // 如果已经登录，放行
      // console.log('已经登录，查看跳转路由',localStorage.getItem('token'));
      // console.log('已经登录，查看跳转路由',to, from, next);
      if(to.path === '/index'){
        store.commit('setMenuItems', 1);
      }else if(to.path === '/AIPictureTool'){
        store.commit('setMenuItems', 2);
      }else if(to.path === '/AITextTool'){
        store.commit('setMenuItems', 3);
      }else if(to.path === '/FreeTools'){
        store.commit('setMenuItems', 4);
      }
      next()
    } else {
      // 如果没有登录，跳转到登录页面
      next('/login')
    }
    // next();
  }
})

Vue.use(Vuex)
Vue.use(ElementUI);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
