<template>
    <div class="dialogmain">
        <!-- 登录弹窗 -->
        <el-dialog class="" title="" :visible.sync="centerDialogVisible" width="380px" :close-on-click-modal="false"
            center>
            <el-form ref="myForm" id="myForm" :model="login" @submit.native.prevent="handleLogin"
                @keyup.enter.native="handleEnter">
                <h1 style="text-align: center;">欢迎加入本地薯</h1>
                <div style="display: flex; justify-content: center" class=" top-bwttom">
                    <el-radio-group v-model="loginRole" size="mini" class="dialog-title" text-color="#fff"
                        fill="#FE2749">
                        <el-radio-button label="2">账号密码登录</el-radio-button>
                        <el-radio-button label="3">
                            <span> &nbsp;&nbsp;验证码登录&nbsp;&nbsp; </span>
                        </el-radio-button>
                    </el-radio-group>
                </div>
                <el-input ref="input1" v-model="phone" placeholder="请输入手机号" class="mar-23  top-bwttom"
                    clearable></el-input>

                <div style="position: relative;">
                    <div class="input_box" v-if="loginRole == 3">
                        <el-input ref="input3" id="password" v-model="phonecode" placeholder="请输入手机验证码"
                            class="mar-23 top-bwttom" maxlength="6"></el-input>
                        <div class="active " @click="onSetCode" style="font-size: 24rpx;color: #FE2043;">{{ codeText }}
                        </div>
                    </div>
                    <el-input v-else ref="input2" id="password" v-model="password" placeholder="请输入密码"
                        class="mar-23 top-bwttom" show-password></el-input>
                </div>


                <div style="display: flex;justify-content: space-between;" class="top-bwttom">
                    <XzCode ref="myCanvas"></XzCode>
                    <div>
                        <el-input ref="input1" v-model="code" placeholder="请输入图形验证码" class="mar-23"
                            clearable></el-input>
                    </div>
                </div>
                <div>
                    <el-button class="dyn top-bwttom" type="danger" @click="handleLogin()"
                        style="width: 100%; background-color: #FE2749; border-color: #FE2749;">登录</el-button>
                    <div style="color: #999; text-align: center; font-size: 12px" class="top-bwttom">
                        注册登录即代表同意《本地薯共创平台协议》
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
// import XzCode from "@/components/XzCode/index";
import XzCode from "@/components/XzCode/XzCode";
export default {
    data() {
        return {
            loginRole: "2",
            activeName: "first",
            centerDialogVisible: false,
            src: "",
            name: "home",
            login: {
                username: null,
                password: null,
            },
            text1: "本地薯共创平台",
            text2: "专注本地生活技术赋能",
            title: "数宝助手",
            code: "",//图形验证码
            phonecode: "",//手机验证码
            codeText: "获取验证码",
            //验证码已发
            readonly: false,
            phone: "",//手机号
            password: "",//密码
            clear: null,//定时器验证码请求
            userInfo:{}//用户信息
        };
    },
    components: {
        XzCode,
    },
    computed: {
        shouldOpenLogin() {
            return this.$store.state.shouldOpenLogin;
        },

    },
    watch: {
        shouldOpenLogin(newVal) {
            if (newVal) {
                this.open();
                this.$store.dispatch('closeLogin');
            }
        },

    },
    methods: {
        open() {
            this.centerDialogVisible = true;
        },
        async handleLogin() {
            //判断手机号是否正确
            let reg = /^1[3-9]\d{9}$/;
            let mobile = this.phone;
            if (!reg.test(mobile)) {
                this.$message.error("手机号格式不正确");
                return;
            }
            //判断验证码是否正确
            if (this.loginRole == 3 && this.code.length != 4) {
                this.$message.error("验证码格式不正确");
                return;
            }
            //判断密码是否正确
            if (this.loginRole == 2 && this.password.length < 6) {
                this.$message.error("密码格式不正确");
                return;
            }
            //判断图像验证码是否正确 去除大小写，去除空格
            console.log(this.$refs.myCanvas.code, this.code, "图形验证码格式不正确");
            //大小写转换
            if (this.$refs.myCanvas.code.toLowerCase().trim() != this.code.toLowerCase().trim()) {
                this.$message.error("图形验证码格式不正确");
                return;
            }
            // 验证码登录
            if(this.loginRole == 3){
                let res = await  this.$newapi.loginCode({
                    mobile: this.phone,
                    code: this.phonecode,
                });
                console.log("res",res);
                if (res.code === 200) {
                    this.centerDialogVisible = false;
                    await window.localStorage.setItem('token', res.data.token);
                    await this.getUserInfo();
                    //刷新进入当前页面
                    this.$message.success("登录成功");
                } else {
                    this.$message.error(res.msg);
                }
            }else{
                //手机号登录
                let res = await this.$newapi.login({
                    mobile: this.phone,
                    password: this.password,
                });

                console.log("res",res);
                if (res.code === 200) {
                    this.centerDialogVisible = false;
                    await window.localStorage.setItem('token', res.data.token);
                    await this.getUserInfo();
                    //刷新进入当前页面
                    this.$message.success("登录成功");
                } else {
                    this.$message.error(res.msg);
                }

            }
        },
        //根据token查询个人信息
        async getUserInfo() {
            let res = await this.$newapi.getUserInfo();
            console.log("res",res);
            if (res.code === 200) {
                let resnum = res.data.identity == 4?2:3;
                this.$store.commit('setUserInfo', res.data);
                this.$store.commit('setLoginRole',resnum );
                this.$store.commit('setOpenid', res.data.id);
                window.localStorage.setItem('userInfo', JSON.stringify(res.data));
                window.localStorage.setItem('loginRole', resnum);
                console.log(this.$store.state.userInfo,this.$store.state.loginRole, "this.$store.state.userInfo");
            } else {
                this.$message.error(res.msg);
            }
        },

        //验证码按钮文字状态
        getCodeState() {
            this.clear && clearInterval(this.clear);
            const _this = this;
            this.readonly = true;
            this.codeText = '60S';
            var s = 60;
            this.clear = setInterval(() => {
                s--;
                _this.codeText = s + 'S';
                if (s <= 0) {
                    clearInterval(_this.clear);
                    _this.codeText = '获取验证码';
                    _this.readonly = false;
                }
            }, 1000);
        },
        // 发送验证码
        async onSetCode() {
            if (this.readonly) {
                return;
            }
            if (!this.phone) {

                this.$message.error('请输入手机号');
                return;
            }
            // 手机号校验
            if (!/^1[3-9]\d{9}$/.test(this.phone)) {
                this.$message.error('手机号格式不正确');
                return;
            }
            // 发送验证码
            console.log("发送验证码");
            // return;
            const res = await this.$newapi.phonecode({
                phone: this.phone
            })
            console.log("res",res);
            if (res.code === 200) {
                this.$message.success("验证码发送成功");
                this.getCodeState();
            } else {
                this.$message.error(res.msg);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.top-bwttom {
    margin-bottom: 20px;
}

::v-deep .el-dialog {
    border-radius: 16px;
}



.dialog-center {
    .el-dialog {
        border-radius: 20px;
        position: absolute;
        top: 5vw;
        right: 10vw;
    }

    .el-dialog__body {
        padding: 0px 75px 25px 75px !important;

        color: #333;

        .tabs-center {
            margin-top: 15px;

            .el-tabs__nav-wrap::after {
                display: none;

            }

            .el-tabs__item {
                transform: translate(0px, 5px);
            }

            .el-tabs__item.is-active {
                color: #333;
            }

            .el-tabs__active-bar {
                background-color: #FE2749;
                border-radius: 2px;
            }

            .el-icon-close:before {
                font-size: 20px;
            }

        }
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: #ff2442 !important;
        outline: 0;
    }

    .el-dialog__headerbtn .el-dialog__close:hover {
        color: #ff2442;
    }

    .el-radio-group {
        .el-radio-button__inner:hover {
            color: #ff2442;
        }

        .el-radio-button__inner {
            color: #333;
        }
    }

    .dialog-title {
        font-size: 24px;
        width: 100%;
        text-align: center;
    }

    .mar-23 {
        margin-bottom: 23px;
    }

    .dyn {
        width: 100%;
        border-radius: 50px;
        background-color: #FE2749;
        border-color: #FE2749;
        font-size: 16px;
        margin-top: 20px;
    }
}

.active {
    color: rgb(254, 32, 67);
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
}
</style>
