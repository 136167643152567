window.appConfig = {
    // 应用名称
    appName: 'vue-admin-beautiful',
    // 应用版本
    appVersion: '1.0.0',
    // 应用描述
    appDescription: 'vue-admin-beautiful',
    // 应用图标
    appLogo: require('@/assets/logo.png'),
    // 应用图标高清

    // 配置文件地址
    baseUrl: {
        // development:'https://test.xhsbds.com',// 开发环境
        development: 'https://admintestyyd.xhsbds.com',// 开发环境
        production: 'https://yyd-admin.1086ai.com',// 正式环境
    },
    newbaseUrl: {
        development:'https://apitestyyd.xhsbds.com',// 开发环境
        production:'https://yyd-mobile-api.1086ai.com',// 正式环境
        // development: 'https://api.xhsbds.com',//"//新接口（获取用户验证码等信息）
        // production: 'https://api.xhsbds.com/',// 正式环境
    }
  }


