<template>
  <div class="logo-bar">
    <img src="@/assets/logo.png" alt="logo" class="logo" style="width: 46px;height: 46px;margin-right: 0px;">
    <span class="navtitle">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name:"CompanyLogo",
  data() {
    return {
      title: '薯宝助手 | 专业工作台',
    }
  }
}
</script>

<style lang="less">
@line-height: 65px;
@left-width: 150px;

.logo-bar {
  // padding-left: @left-width;
  display: flex;
  align-items: center;
  .navtitle {
    margin-left: 8px;
    font-size: 22px;
    font-weight: 600;
    line-height: @line-height ;
  }
}
</style>